import init from "./init";
import {Contact_mv} from "../controllers/contact_mv";

class IndexMV {
    /* STATIC */

    public static async init(): Promise<void> {
        init.pushDelayed(Contact_mv);
    }

    /* INSTANCE */
}

init.push(IndexMV);
