import axios from "axios";


import {IRespuesta} from "services-comun/modules/net/interface";

interface IForm {
    name:string,
    surename:string,
    email:string,
    interests:string,
    message:string,
    accept:boolean
}

//sdfdsf
const emailRegex= /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export class Contact_mv {
    /* STATIC */
    public static async init(): Promise<void> {
        new this();
    }

    /* INSTANCE */
    protected readonly bb1: HTMLElement;
    protected readonly bb2: HTMLElement;
    protected readonly bb3: HTMLElement;

    protected readonly b1: HTMLLIElement;
    protected readonly b2: HTMLLIElement;
    protected readonly b3: HTMLLIElement;

    protected readonly name: HTMLInputElement;
    protected readonly surename: HTMLInputElement;
    protected readonly email: HTMLInputElement;
    protected readonly email_error_msg: HTMLElement;
    protected readonly interests: HTMLInputElement;
    protected readonly message: HTMLInputElement;
    protected readonly accept: HTMLInputElement;
    protected readonly accept_msg: HTMLElement;

    protected readonly url: string;

    protected readonly buttons_nav:HTMLLIElement;
    protected control_nav: HTMLInputElement;

    protected readonly molinos:HTMLElement;
    protected readonly placas:HTMLElement;




    public constructor(){

        this.bb1 = document.getElementById("bb1") as HTMLElement;
        this.bb2 = document.getElementById("bb2") as HTMLElement;
        this.bb3 = document.getElementById("bb3") as HTMLElement;

        this.b1 = document.getElementById("b1") as HTMLLIElement;
        this.b2 = document.getElementById("b2") as HTMLLIElement;
        this.b3 = document.getElementById("b3") as HTMLLIElement;

        this.name = document.getElementById('input_name') as HTMLInputElement;
        this.surename = document.getElementById('input_surename') as HTMLInputElement;
        this.email = document.getElementById('input_email') as HTMLInputElement;
        this.email_error_msg = document.getElementById('input_email_error_msg') as HTMLElement;
        this.interests = document.getElementById('input_interests') as HTMLInputElement;
        this.message = document.getElementById('input_message') as HTMLInputElement;
        this.accept = document.getElementById('input_accept') as HTMLInputElement;
        this.accept_msg = document.getElementById('input_accept_msg') as HTMLElement;

        this.url= "";

        this.buttons_nav = document.getElementById("buttons_nav") as HTMLLIElement;
        this.control_nav = document.getElementById('control_navegacion') as HTMLInputElement;
        let options = this.buttons_nav.getElementsByTagName('a');
        options[4].remove();
        this.molinos = document.getElementById("molinos") as HTMLElement;
        this.placas = document.getElementById("placas") as HTMLElement;

        this.adaptUserAgent();

        this.b1.onclick = this.b2.onclick = this.b3.onclick = () => {
            this.processSendMail().then(async ()=>{
                this.setSuccessButton();
            }).catch(async (err)=>{
                this.setErrorButton();
                console.log("Error sending form", err);
            });
        }
//coment
        this.buttons_nav.onclick = () => {
            this.control_nav.checked=false;
        }

    }

    private async processSendMail(): Promise<void> {
        const form = this.getFormValues();

        if (!form.accept || form.email.length==0 || !emailRegex.test(form.email)) {
            return Promise.reject("You must accept Legal terms and indicate an email");
        }


        await this.sendMail(form);
    }

    private getFormValues(): IForm {
        return {
            name: this.name?.value??"",
            surename: this.surename?.value??"",
            email: this.email?.value??"",
            interests: this.interests?.value??"",
            message: this.message?.value??"",
            accept: this.accept?.checked??false,
        }
    }


    private async sendMail(form : IForm): Promise<void> {
        const {status, data} = await axios.get<IRespuesta<void>>(`${this.url}/web/mail/`, {
            params: {
                name: form.name,
                surename: form.surename,
                email: form.email,
                interests: form.interests,
                message: form.message,
            },
        }).catch(async (err)=>{
            if (axios.isAxiosError(err)) {
                if (err.response != undefined) {
                    return Promise.reject(err.response.statusText);
                }
                if (err.request!=undefined) {
                    return Promise.reject(err.request);
                }
                return Promise.reject(err.message);
            }
            return Promise.reject(err);
        });

        if (status!=200 || !data.ok) {
            return Promise.reject(data);
        }
    }

     private setErrorButton(): void {
         const form = this.getFormValues();

        if(form.email.length==0){
            this.email.className = 'texto texto-2 error';
            this.email_error_msg.innerText = "This field is required";
        }else if(!emailRegex.test(form.email)){
            this.email.className = 'texto texto-2 error';
            this.email_error_msg.innerText = "Please introduce a valid email";
        } else{
            this.email.className = 'texto texto-2';
            this.email_error_msg.innerText = "";
        }

        if(!this.accept.checked){
            this.accept.className = 'error';
            this.accept_msg.innerText ="I have read and accept the legal terms & conditions (This field is required)";
        }else{
            this.accept.className = 'texto-2';
            this.accept_msg.innerText ="I have read and accept the legal terms & conditions";
        }

        this.bb1.style.display = 'none'
        this.bb2.style.display = 'flex';
        this.bb3.style.display = 'none';
    }

    private setSuccessButton(): void {
        this.email.className = 'texto texto-2';
        this.email_error_msg.innerText = "";

        this.accept.className = 'texto-2';
        this.accept_msg.innerText ="I have read and accept the legal terms & conditions";

        this.bb1.style.display = 'none';
        this.bb2.style.display = 'none';
        this.bb3.style.display = 'flex';
        (document.getElementById('f1') as HTMLFormElement).reset();
    }

   private adaptUserAgent():void{
       this.molinos.style.display='none';
        if(this.getMobileOS()=="iOS"){
            this.placas.style.display='none';
        }
    }

    private getMobileOS(): string {
        const ua=navigator.userAgent;
        if (/android/i.test(ua)) {
            return "Android"
        }
        else if ((/iPad|iPhone|iPod/.test(ua)) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)){
            return "iOS"
        }
        return "Other"
    }
}
